@import url('https://use.typekit.net/jtb7qkq.css');
@font-face {
  font-family: 'Helvetica LT Std';
  src: url('./assets/fonts/HelveticaLTStd-Roman.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}
.App {
  overflow-x: hidden;
  .intro {
    height: 100vh;
    width: 100%;
    background: url('./assets/hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    @media screen and (max-width: 768px) {
      background: #dbd3b9;
    }
    .desktopLogo {
      position: absolute;
      width: calc(100vw * 388 / 1760);
      top: calc(100vh * 80 / 1040);
      left: calc(100vw * 200 / 1760);
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .tabletIntro {
      height: 100vh;
      position: relative;
      @media screen and (min-width: 769px) {
        display: none;
      }
      .tabletLogoWrapper {
        width: 100%;
        background: black;
        height: calc(100vh * 160 / 1000);
        min-height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tabletLogo {
          width: 40%;
          @media screen and (max-width: 600px) {
            width: 50%;
          }
        }
      }
      .tabletBg {
        width: 100%;
        max-height: 840px;
        height: calc(100vh * 840 / 1000);
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .tabletIntroBg {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }

        .mobileIntroBg {
          display: none;
          object-fit: contain;
          object-position: bottom;
          @media screen and (max-width: 600px) {
            display: block;
            background: black;
          }
        }
      }
    }
  }
  .content {
    padding: 0 calc(100vw * 60 / 1760);
    padding-bottom: calc((100vh * 250 / 1760) + 100px);
    padding-top: calc(100vh * 142 / 1040);
    background: white;
    margin: 0;
    @media screen and (max-width: 768px) {
      padding: 0;
      background: #dbd3b9;
      padding-bottom: calc(100vh * 88 / 1000);
      padding-top: 50px;
      margin-bottom: calc(100vh * 202 / 1000);
    }
    @media screen and (max-width: 600px) {
      padding: 0;
      background: #dbd3b9;
      padding-bottom: calc(100vh * 54 / 900);
      padding-top: 20px;
      margin-bottom: calc(100vh * 108 / 1000);
    }

    position: relative;
    height: 100%;
    .artWrapper {
      display: flex;
      align-items: center;
      position: relative;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .artBg {
        width: calc(100vw * 1229 / 1760);
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .tabletPicture {
        width: 100%;
        @media screen and (min-width: 769px) {
          display: none;
        }
      }
      .contentRight {
        position: absolute;
        right: 0;
        @media screen and (max-width: 768px) {
          position: relative;
        }
        .t3 {
          background: #dbd3b9;
          padding: 0 calc(100vw * 88 / 1760);
          padding-top: calc(100vh * 66 / 1040);
          padding-bottom: calc(100vh * 78 / 1040);
          font-family: novecento-sans;

          width: calc(100vw * 696 / 1760);
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          header {
            display: flex;
            align-items: center;
            img {
              width: calc(100vw * 123 / 1040);
              @media screen and (max-width: 768px) {
                width: 50vw;
              }
            }
          }
          .t3Content {
            margin-top: 45px;
            p {
              font-family: 'Helvetica LT Std';
              font-style: normal;
              font-weight: 400;
              font-size: calc(100vw * 20 / 1760);
              line-height: calc(100vw * 32 / 1760);

              @media screen and (max-width: 1024px) {
                font-size: calc(100vw * 13 / 1024);
                line-height: calc(100vw * 17 / 1024);
              }
              @media screen and (max-width: 768px) {
                font-size: 25px;
                line-height: 170%;
              }

              @media screen and (max-width: 600px) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }

        .download {
          transform: translateX(calc(100vw * 303 / 1760));
          width: calc(100vw * 240 / 1760);
          margin-top: 23px;
          margin-left: 23px;
          font-family: novecento-sans;
          color: white;
          position: absolute;
          left: 0;
          bottom: initial;
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          z-index: 2;
          @media screen and (max-width: 768px) {
            transform: translateY(50%);
            bottom: -88px;
            margin: 0;
            margin-left: calc(100vw * 88 / 1760);
            width: calc(100% - (100vw * 176 / 1760));
            flex-direction: row;
          }
          @media screen and (max-width: 768px) {
            transform: translateY(50%);
            bottom: -54px;
            margin: 0;
            margin-left: calc(100vw * 88 / 1760);
            width: calc(100% - (100vw * 176 / 1760));
            flex-direction: row;
          }

          cursor: pointer;
          .downloadOverlay {
            background: url('./assets/downloadBg.png');
            background-repeat: no-repeat;
            background-size: cover;
            filter: brightness(0%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;

            transition: filter 0.25s;

            @media screen and (max-width: 768px) {
              filter: brightness(100%);
              background: url('./assets/downloadBgTablet.png');
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .downloadText {
            padding-top: calc(100vh * 36 / 1040);
            padding-bottom: calc(100vh * 49 / 1040);
            padding-left: calc(100vw * 31 / 1760);
            padding-right: calc(100vw * 63 / 1760);

            @media screen and (max-width: 768px) {
              padding: 50px 36px;
            }
            @media screen and (max-width: 600px) {
              padding: 21px 15px;
            }
            h3 {
              font-family: novecento-sans-condensed;
              font-style: normal;
              font-weight: 600;
              font-size: calc(100vw * 40 / 1760);
              line-height: calc(100vw * 48 / 1760);
              text-transform: uppercase;
              margin: 0;
              width: auto;
              @media screen and (max-width: 1024px) {
                font-size: calc(100vw * 23.27 / 1024);
                line-height: calc(100vw * 28 / 1024);
              }
              @media screen and (max-width: 768px) {
                font-size: 57.557px;
                line-height: 69px;
                width: 350px;
              }
              @media screen and (max-width: 600px) {
                font-size: 24px;
                line-height: 29px;
                width: 150px;
              }
            }
          }
          hr {
            background: white;
            border: none;
            border-top: 2px solid white;
            margin: 0;
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          .downloadButton {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-top: 8px;

            padding-right: 15px;
            padding-bottom: calc(100vw * 8 / 1040);
            img {
              width: calc(100vw * 45 / 1760);
              @media screen and (max-width: 768px) {
                width: calc(100vw * 108 / 768);
              }
              @media screen and (max-width: 600px) {
                width: calc(100vw * 80 / 768);
              }
            }
          }
        }

        .download:hover .downloadOverlay {
          filter: brightness(100%);
        }
      }
    }
  }

  .contactInfoWrapper {
    border-top: 1px solid black;
    margin: 0 calc(100vw * 88 / 1760);
    padding-top: calc(100vh * 130 / 1040);
    padding-bottom: calc(100vh * 120 / 1040);
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 600px) {
      padding-top: calc(60 / 900);
    }
    .leasingInquiries {
      width: calc(100vw * 500 / 1760);
      display: flex;
      flex-direction: row;
      padding-left: calc(100vw * 140 / 1760);
      padding-right: calc(100vw * 165 / 1760);
      justify-content: center;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0;
        width: 100%;
        margin-bottom: calc(100vh * 24 / 1000);
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 30px;
      }
      h3 {
        font-family: novecento-sans-condensed;
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 72px;
        text-transform: uppercase;
        margin: 0;

        @media screen and (max-width: 1024px) {
          font-weight: 600;
          font-size: 34.9091px;
          line-height: 42px;
        }

        @media screen and (max-width: 768px) {
          font-weight: 600;
          font-size: 86.3356px;
          line-height: 104px;
        }

        @media screen and (max-width: 600px) {
          font-size: 36px;
          line-height: 43px;
        }
      }
    }
    .contactInfo {
      width: 100%;
      .contactRow:not(:first-of-type) {
        margin-top: calc(100vh * 60 / 1040);
        @media screen and (max-width: 768px) {
          margin-top: 0;
        }
      }
      .contactRow {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .contact {
          flex-basis: 0;
          flex-grow: 1;
          display: flex;
          margin-top: 0;
          @media screen and (max-width: 768px) {
            margin-top: calc(100vh * 48 / 1000);
          }
          @media screen and (max-width: 600px) {
            margin-top: 20px;
          }
          .contactInner {
            display: flex;
            flex-direction: column;

            * {
              margin: 0;
              font-family: 'Helvetica LT Std';
              font-style: normal;
              font-weight: 400;

              font-size: calc(100vw * 21 / 1760);
              line-height: calc(100vw * 29 / 1760);

              text-decoration: none;
              color: black;

              @media screen and (max-width: 1024px) {
                font-style: normal;
                font-weight: 400;
                font-size: calc(100vw * 12.2182 / 1024);
                line-height: calc(100vw * 17 / 1024);
              }
              @media screen and (max-width: 768px) {
                font-style: normal;
                font-weight: 400;
                font-size: 38.3714px;
                line-height: 58px;
              }
              @media screen and (max-width: 600px) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  footer {
    height: calc(100vh * 100 / 1040);
    min-height: 60px;

    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(100vw * 60 / 1760);

    @media screen and (max-width: 768px) {
      height: auto;
      padding: 0 calc(100vw * 68 / 768);
      padding-top: calc(100vh * 110 / 1000);
      padding-bottom: calc(100vh * 72 / 1000);
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 600px) {
      padding-top: 60px;
      padding-bottom: 30px;
    }

    small {
      color: white;
      font-family: 'Helvetica LT Std';
      font-style: normal;
      font-weight: 400;
      font-size: calc(100vw * 16 / 1760);
      line-height: 29px;
      text-align: left;
      @media screen and (max-width: 1024px) {
        font-weight: 400;
        font-size: 12.2182px;
        line-height: 17px;
      }

      @media screen and (max-width: 768px) {
        font-weight: 400;
        font-size: 33.5749px;
        line-height: 50px;
        text-align: center;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .footerLogos {
      display: flex;
      align-items: center;
      justify-content: initial;
      width: auto;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
        width: 100%;
        margin-bottom: calc(100vh * 144 / 1000);
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 60px;
      }
      .hinesLogo {
        width: calc(100vw * 120 / 1760);
        @media screen and (max-width: 768px) {
          width: calc(100vw * 208 / 768);
        }
      }
      .urbanLogo {
        margin-left: calc(100vw * 60 / 1760);
        width: calc(100vw * 181 / 1760);
        @media screen and (max-width: 768px) {
          width: calc(100vw * 316 / 768);
        }
      }
    }
  }
}
